import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import './blog.css';
import SEO from '../components/SEO'
import Hero from '../components/hero/hero'
import siteConfig from '../../data/siteConfig'
import Wrapper from '../components/wrapper'


const IndexPage = (props) => {
  const data = props.data.allMarkdownRemark.group
  const postList = props.data.allMarkdownRemark;
  const title = siteConfig.blogTitle
  return (
    <Layout>
      <SEO title="Blog" />
      <Hero
        heroImg={siteConfig.siteCover}
        title={title}
      />
      <Wrapper>
        <div className="container">

          {
            data.map(tag => (
              <div className="item">
              <Link to={`/${tag.fieldValue}`} >
                {tag.fieldValue} {`(${tag.totalCount})`}
              </Link>
              </div>
            ))
          }
        </div>
        <br/>
        <div>
          {postList.edges.map(({ node }, i) => (
            <Link to={node.fields.slug} key={i} className="link" >
              <div className="post-list">
                <h1>{node.frontmatter.title}</h1>
                <span>{node.frontmatter.date}</span>
                <p>{node.excerpt}</p>
              </div>
            </Link>
          ))}
        </div>
      </Wrapper>
    </Layout>
  )
}

export default IndexPage;
export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000, sort: { order: DESC, fields: [frontmatter___date] }
      filter: {frontmatter: {title: {ne: ""}}}) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    edges {
      node {
        fields{
          slug,
        }
        excerpt(pruneLength: 250)
        frontmatter {
          date
          title
          previousBlog
          nextBlog
        }
      }
    }
    }
  }

`
